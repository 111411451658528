<template>
  <section class="section-contact" id="contact">
    <div class="header-contact">
      <p class="subtitle-contact">CONTATO</p>
      <h2 class="title-contact">Fale com a Dopster</h2>
    </div>
    <div class="form-contact">
      <div class="info-contact">
        <div class="andress-div">
          <div class="centralized-divs">
            <img class="icon-contact" src="../../assets/andress.png" alt />
            <br />
            <br />
            <h4>Endereço</h4>
            <p>Av. Eng. Luiz Carlos Berrini, 1681, salas 111 e 112</p>
          </div>
        </div>
        <div class="email-div">
          <div class="centralized-div">
            <img class="icon-contact" src="../../assets/email.png" alt />
            <br />
            <br />
            <h4>Email</h4>
            <p>falecom@dopster.io</p>
          </div>
        </div>
      </div>
      <form @submit.prevent="handleSend" class="form-group">
        <div class="form-container">
          <input
            class="input-name"
            v-model.lazy="name"
            minlength="3"
            type="text"
            placeholder="Seu nome"
          />
          <input
            class="input-email"
            maxlength="50"
            v-model.lazy="email"
            type="email"
            placeholder="Seu email"
          />
          <input class="input-matters" v-model.lazy="subject" type="text" placeholder="Assunto" />
          <b-row class="mt-2">
            <b-col sm="3"> </b-col>
            <b-col sm="15">
              <b-form-textarea
                minlength="10"
                v-model.lazy="message"
                id="textarea-no-auto-shrink"
                placeholder="Mensagem"
                rows="6"
                max-rows="7"
              ></b-form-textarea>
            </b-col>
          </b-row>
          <button class="btn btn-primary send-contact" type="submit">Enviar</button>
        </div>
      </form>
    </div>
  </section>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      name: '',
      email: '',
      subject: '',
      message: '',
      toEmailDopster: 'falecom@dopster.io',
      fromEmail: 'admin@dopster.io',
    };
  },
  methods: {
    handleSend() {
      if (
        this.name.length > 0
        && this.email.length > 0
        && this.subject.length > 0
        && this.message.length > 0
      ) {
        axios
          .post('https://dopster-email.dopster.io/api/Email/EnviarEmail', {
            to: this.toEmailDopster,
            body: `<html><body>Nome: ${this.name}<br/>E-mail: ${this.email}<br/>Assunto: ${this.subject}<br/>Mensagem: ${this.message}</body></html>`,
            subject: this.subject,
            from: this.fromEmail,
            isHTML: true,
            authorize: 'FDWR$#%Y&HF%$#@$#¨%$&%HTV%$',
          })
          .then((res) => {
            this.limparForm();
            console.log(res);
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    limparForm() {
      this.name = '';
      this.email = '';
      this.subject = '';
      this.message = '';
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../styles/contact.scss";
</style>
