<template>
  <div class="header-container" :class="{ change_color: scrollPosition > 50 }">
    <img class="logo-dopster" src="../assets/dopsterlogo.png" alt="logo e nome da dopster" />
    <nav class="nav-list">
      <router-link rel=”noreferrer” to="/">
        <p class="nav-item ">Home</p>
      </router-link>
      <p class="nav-item"><a href="#about" class="link-about">Sobre a Dopster</a></p>
      <!-- <router-link to="/plataform">
        <p class="nav-item">Plataforma</p>
      </router-link> -->
      <p class="nav-item"><a href="#hub" class="link-hub">Hub</a></p>
      <p class="nav-item">
        <a href="#contact" class="link-cantact">Contate-nos</a
        ><b-icon class="icon-contact" icon="headset"></b-icon>
      </p>
    </nav>
    <sidebar />
  </div>
</template>

<script>
import Sidebar from './Sidebar.vue';

export default {
  components: {
    sidebar: Sidebar,
  },

  name: 'Header',
  data() {
    return {
      scrollPosition: null,
    };
  },
  methods: {
    handleScroll(evt, el) {
      alert('Dddd');
      if (window.scrollY > 50) {
        el.setAttribute('style', 'background-color: red;');
      }
      return window.scrollY > 100;
    },
    updateScroll() {
      this.scrollPosition = window.scrollY;
    },
  },
  mounted() {
    window.addEventListener('scroll', this.updateScroll);
  },
};
</script>

<style lang="scss" scoped>
@import "../styles/header.scss";
.change_color {
  background: #fff;
  padding: 15px 0;
  box-shadow: 0px 2px 20px rgb(1 41 112 / 10%);
}

a {
  text-decoration: none;
}

a:visited {
  color: black;
}
</style>
