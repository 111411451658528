<!-- <div class="sidebar">
    <button class="bar-button"><b-icon icon="list" scale="3"></b-icon></button>
  </div> -->

<template>
  <div class="sidebar">
    <b-icon class="button-modal" v-b-toggle.sidebar-1 icon="list"></b-icon>
    <b-sidebar id="sidebar-1" title="DOPSTER" shadow backdrop backdrop-variant="dark">
      <div class="px-3 py-2 sidebar-open">
        <div class="div-sidebar">
          <img class="logo-sidebar" src="../assets/Dopster.png" fluid thumbnail />
        </div>
        <div class="list-sidebar">
          <ul class="list-routes">
            <router-link rel="”noreferrer”" to="/">
              <li class="list-text">Início</li>
            </router-link>
            <li class="list-text">
              <a rel="”noreferrer”" href="#" class="about-item">Sobre a Dopster</a>
            </li>
            <router-link rel="”noreferrer”" to="/plataform">
              <li class="list-text">Plataforma</li>
            </router-link>
            <li class="list-text">
              <a rel="”noreferrer”" href="https://hub.dopster.io/" target="blank" class="hub-item"
                >Hub</a
              >
            </li>
            <li class="list-text">
              <a rel="”noreferrer”" href="#contact" class="contact-item">Contate-nos</a>
            </li>
          </ul>
        </div>
      </div>
    </b-sidebar>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
@import "../styles/sidebar.scss";
</style>
