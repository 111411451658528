<template>
  <div class="home-main">
    <a rel=”noreferrer” href="https://hub.dopster.io/"><button class="back-to-top">Visite nosso hub</button></a>
    <welcome-section />
    <slogan-section />
    <about-section />
    <card-section/>
    <evolution-section />
    <contact-section/>
    <newsletter-section/>
    <my-footer />
    <copyright-section />
    <privacity-section />
  </div>
</template>

<script>
import Footer from '../components/sections/Footer.vue';
import Copyright from '../components/sections/Copyright.vue';
import Card from '../components/Card.vue';
import Slogan from '../components/sections/Slogan.vue';
import ToEvolve from '../components/sections/ToEvolution.vue';
import Welcome from '../components/sections/Welcome.vue';
import About from '../components/sections/About.vue';
import Contact from '../components/sections/Contact.vue';
import newsletter from '../components/sections/Newsletter.vue';
import Privacity from '../components/Privacity.vue';

export default {
  components: {
    'card-section': Card,
    'my-footer': Footer,
    'copyright-section': Copyright,
    'slogan-section': Slogan,
    'evolution-section': ToEvolve,
    'welcome-section': Welcome,
    'about-section': About,
    'contact-section': Contact,
    'newsletter-section': newsletter,
    'privacity-section': Privacity,
  },
};
</script>

<style lang="scss" scoped>
@import "../styles/home.scss";
</style>
