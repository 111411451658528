<template>
  <div id="app">
    <my-header />
    <router-view />
  </div>
</template>

<script>
import Header from './components/Header.vue';

export default {
  components: {
    'my-header': Header,
  },
};
</script>

<style lang="scss">
@import "./styles/global.scss";

body,
html,
#app {
  margin: 0;
  padding: 0;
  font-family: "Outfit", sans-serif;
}

:root {
  --primary-color: #0d3063;
  --secondary-color: #115599;
  --terthiary-color: #48a2f8;
  --gray-color: #d9d5d2;
}
</style>
