import Vue from 'vue';
import Vuex from 'vuex';
import Lottie from 'vue-lottie';

Vue.component('lottia', Lottie);
Vue.use(Vuex);

export default new Vuex.Store({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  },
});
