<template>
  <footer class="footer-main" id="footer">
    <div class="social-div">
      <div class="social-media">
        <img class="logo-dopster" src="../../assets/dopsterlogo.png" alt="" />
        <br>
        <p class="slogan-footer">Encurtando o caminho entre sua aplicação e o seu público.</p>
        <a rel=”noreferrer” href="https://www.instagram.com/dopster.io/" target="blank"
          ><b-icon class="icon-site" icon="instagram"></b-icon
        ></a>
        <a rel=”noreferrer” href="https://www.linkedin.com/company/dopster/" target="blank"
          ><b-icon class="icon-site" icon="linkedin"></b-icon
        ></a>
        <a rel=”noreferrer” href="https://www.youtube.com/channel/UCDMOWq6yFFgf1WS08Szw9iA" target="blank"
          ><b-icon class="icon-site" icon="youtube"></b-icon
        ></a>
      </div>
    </div>
    <div class="links-div links">
      <div class="links">
        <h4 class="links-text">LINKS</h4>
        <ul class="list">
          <a href="#">
            <li class="home-list">
              <b-icon icon="chevron-compact-right" scale="1" class="arrow-icon"></b-icon> Home
            </li>
          </a>
              <a target="blank" href="https://blog.dopster.io/">
            <li class="home-list">
              <b-icon icon="chevron-compact-right" scale="1" class="arrow-icon"></b-icon> Blog
            </li>
          </a>
          <!-- <a href="/plataform">
            <li class="plataform-list">
              <b-icon icon="chevron-compact-right" scale="1" class="arrow-icon"></b-icon> Plataforma
            </li>
          </a> -->
          <a rel=”noreferrer” href="https://hub.dopster.io/">
          <li class="hub-list">
            <b-icon icon="chevron-compact-right" scale="1" class="arrow-icon"></b-icon> Hub
          </li>
          </a>
          <a rel=”noreferrer” href="https://dopster.io/privacidade.html" target="blank">
          <li class="politic-list">
            <b-icon icon="chevron-compact-right" scale="1" class="arrow-icon"></b-icon> Política de
            privacidade
          </li>
          </a>
        </ul>
      </div>
      <!-- <div class="more-links links">
        <h4 class="more-text">MAIS DOPSTER</h4>
        <ul class="list-more">
          <li class="blog-list">
            <b-icon icon="chevron-compact-right" scale="1" class="arrow-icon"></b-icon> Blog
          </li>
          <li class="init-list">
            <b-icon icon="chevron-compact-right" scale="1" class="arrow-icon"></b-icon> Iniciativas
          </li>
          <a href="https://www.youtube.com/channel/UCDMOWq6yFFgf1WS08Szw9iA">
          <li class="youtube-list">
            <b-icon icon="chevron-compact-right" scale="1" class="arrow-icon"></b-icon> Canal do
            Youtube
          </li>
          </a>
          <li class="new-list">
            <b-icon icon="chevron-compact-right" scale="1" class="arrow-icon"></b-icon> Novidades
          </li>
          <li class="client-list">
            <b-icon icon="chevron-compact-right" scale="1" class="arrow-icon"></b-icon> Parceiros
          </li>
        </ul>
      </div> -->
    </div>
    <div class="email-div">
      <h4 class="tell-me">FALE COM A DOPSTER</h4>
      <p class="p-tell"><strong>falecom@dopster.io</strong></p>
    </div>
  </footer>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
@import "../../styles/footer.scss";
</style>
