<template>
  <section v-show="!cookiesVisible" class="privacity-container">
    <p>
      O Dopster usa
      <span> cookies </span> para garantir que você obtenha a melhor experiência (veja nossa
      <a href="privacidade.html">Política de privacidade</a>).
    </p>
    <button v-on:click="cookies(true, $event)" id="accept-cookies">Aceitar 🍪</button>
    <button v-on:click="cookies(false, $event)" id="deny-cookies">Negar 🙅</button>
  </section>
</template>

<script>
export default {
  data() {
    return {
      cookiesVisible: localStorage.getItem('dopsterCookies') === 'true',
    };
  },
  methods: {
    cookies(accept, { target }) {
      if (accept) {
        localStorage.setItem('dopsterCookies', true);
      } else {
        localStorage.setItem('dopsterCookies', false);
      }
      target.parentNode.classList.add('hide');
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../styles/privacity.scss";
</style>
