<a name="about"></a>
<template>
  <section class="main-container animated animatedFadeInUp fadeInUp">
    <section class="text-container">
      <div class="text">
        <div></div>
        <h1 class="welcome-text">Bem-vindo à Dopster!</h1>
        <br />
        <p class="paragraph-welcome">
          Dopster é DevOps. Somos uma plataforma como serviço (PaaS) de DevOps. Levamos e
          implementamos a idéia do seu negócio até a nuvem para que seus aplicativos agreguem valor
          a seus usuários finais e aos seus clientes.
          <br />
          <br />
          Por meio da automação, oferecemos recursos flexíveis e com escalabilidade, o que significa
          a possibilidade de atualizações frequentes das aplicações da sua empresa e mais dinamismo
          na utilização da infraestrutura.
          <br />
          <br />
        </p>
        <b-button v-b-modal.modal-scrollable class="button-modal"
          >Ler mais <b-icon icon="arrow-right-short" aria-hidden="true"></b-icon
        ></b-button>
        <br />
        <br />
        <b-modal
          id="modal-scrollable"
          size="lg"
          scrollable
          title="SOBRE A DOPSTER"
          class="modal-open title-modal"
        >
          <p class="my-4" v-for="i in 20" :key="i"></p>
          <p>
            <strong class="strong-modal">Velocidade</strong>
            <br />
            <br />
            Crie a sua vantagem competitiva no mercado. Adapte-se de forma ágil e obtenha mais
            eficiência na geração de resultados. Apresente a seus usuários finais inovações e
            melhorias do seu produto de forma rápida.
            <br />
            <br />
            <strong class="strong-modal">Confiabilidade</strong>
            <br />
            <br />
            Mantenha a experiência positiva para seus clientes. Ofereça confiança e segurança na
            entrega garantindo qualidade nas atualizações e estabilidade na infraestrutura.
            <br />
            <br />
            <strong class="strong-modal">Escala</strong>
            <br />
            <br />
            Sua equipe será capaz de, em minutos, trabalhar em ambientes de computação em nuvem mais
            complexos e desativá-los quando não houver mais a demanda. Tenha mais foco em seus
            serviços e práticas ao invés de perder tempo gerenciando e configurando servidores
            físicos locais.
            <br />
            <br />
            <strong class="strong-modal">Segurança</strong>
            <br />
            <br />
            Oferecemos mais estabilidade e segurança com políticas de conformidade automatizadas,
            protegendo dados, aplicativos e infraestrutura contra possíveis ameaças.
            <br />
            <br />
          </p>
          <h3><strong class="strong-modal">Nossos Serviços</strong></h3>
          <br />
          <br />
          <p>
            <strong class="strong-modal">HUB</strong>
            <br />
            <br />
            HUB é o nosso centro de aplicativos. Aqui, empresas, empreendedores e desenvolvedores
            hospedam suas APIs, desenvolvidas em qualquer linguagem, e disponibilizam para o mundo
            todo, ou somente para aqueles com permissão. Feito para aqueles que querem ter seu
            próprio DevOps, sem o ônus de montar uma equipe local altamente especializada para isso.
            A publicação do aplicativo na web é feita em minutos e com segurança. No HUB, APIs de
            Serviços OnDemand estão disponíveis para quem busca por soluções sem precisar investir
            capital e tempo no desenvolvimento de um aplicativo a partir do zero. Em nosso HUB você
            encontra os aplicativos que podem otimizar os resultados e processos de sua empresa.
            <br />
            <br />
            <strong class="strong-modal">Transformação digital</strong>
            <br />
            <br />
            Redução de custos, escalabilidade, produtividade e segurança são objetivos incríveis que
            sua empresa pode alcançar com a computação em nuvem. A Dopster pode ajudar a sua empresa
            na jornada de migração para a nuvem: do planejamento racional à proposta de melhorias,
            da migração de plataforma à fase de testes e homologação. A sua jornada para nuvem é
            aqui na Dopster.
          </p>
        </b-modal>
      </div>
    </section>
    <section class="image-container">
      <lottie class="image-home" :options="defaultOptions" />
    </section>
  </section>
</template>

<script>
import * as animationData from '../../assets/Animations/hero3.json';

export default {
  data() {
    return {
      defaultOptions: {
        animationData: animationData.default,
      },
    };
  },
};
</script>

<style lang="scss" scoped>
@import "../../styles/welcome.scss";
@import "../../styles/custom.css";
</style>
