<template>
  <div class="newsletter-section">
    <div class="title-div">
      <h4 class="title-newsletter">Newsletter</h4>
      <p>Receba informações e novidades da Dopster</p>
    </div>
    <div class="input-div">
      <form @submit.prevent="postEmail()">
        <input class="input-newsletter" v-model="email" type="email" name="email" />
        <input class="button-send" type="submit" value="Inscrever" name="submit" />
      </form>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      email: '',
    };
  },
  components: {},
  methods: {
    postEmail() {
      axios
        .post('https://sendmail.dopster.io/api/Email/Sendmail', {
          email: this.email,
        })
        .then((res) => {
          console.log(res);
          alert(res.data.message);
        })
        .catch(() => {
          alert('Ocorreu algum erro, tente novamente, caso persista, nos contate pelo email!');
        });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../styles/newsletter.scss";
</style>
