import Vue from 'vue';
import { BootstrapVue, IconsPlugin, BootstrapVueIcons } from 'bootstrap-vue';
import Lottie from 'vue-lottie';
import VeeValidate, { Validator } from 'vee-validate';
import App from './App.vue';
import router from './router';
import store from './store';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';

const dictionary = {
  en: {
    messages: {
      email: () => 'E-mail inválido, verifique e tente novamente!',
    },
  },
  ar: {
    messages: {
      email: 'حاجة عربي',
    },
  },
};

// Override and merge the dictionaries
Validator.localize(dictionary);

const validator = new Validator({ first_name: 'alpha' });

validator.localize('ar'); // now this validator will generate messages in Arabic.

Vue.use(VeeValidate);
// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue);
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin);
Vue.use(BootstrapVueIcons);

Vue.config.productionTip = false;

// Configuração do Lottie
Vue.component('lottie', Lottie);

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
